'use client';

import React from 'react';
import { colors } from '@/lib/theme/palette';
import IconProps from './icons.types';
import { SvgIcon } from '@mui/material';

export type { IconProps };

const Icon = (props: IconProps) => {
  const { width, height, color, icon, className, sx, ...othersProps } = props;

  return (
    <SvgIcon
      component="svg"
      className={className}
      sx={{
        fill: color || colors.black500,
        width: width || { xs: 18, sm: 26 },
        height: height || { xs: 18, sm: 26 },
        transition: 'fill 0.2s ease-in-out',
        ...sx,
      }}
      {...othersProps}
    >
      <use href={`/icons.sprite.svg#icon-${icon || 'sales-tools'}`} />
    </SvgIcon>
  );
};

export default Icon;
